<template>
  <div class="csv-export">
    <button @click="onOpen">csv</button>
    <sweet-modal ref="modal" width="75%">
      <form @submit.prevent="onCsv">
        <header>
          Export CSV
        </header>
        <p>
          <input type="checkbox" v-model="current" :id="'current'"/>
          <label :for="'current'">status actuel</label>
        </p>
        <p>
          <ul>
            <li v-for="state in Object.keys(Labels)">
              <input type="checkbox" v-model="checkedStates" :value="state" :id="state"/>
              <label :for="state">{{ Labels[state].plural }}</label>
            </li>
            <li @click="checkedStates.splice(0, checkedStates.length, ...Object.keys(Labels))">Tous</li>
            <li @click="checkedStates.splice(0, checkedStates.length)">Aucun</li>
          </ul>
        </p>
        <p class="preview" v-if="previewing || count !== null">
          <spinner v-if="previewing" style="transform: scale(0.2, 0.2);"></spinner>
          <table v-else-if="preview.length > 1">
            <thead>
              <tr>
                <template v-for="label in thead">
                  <th>{{ label }}</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="row in tbody">
                <tr>
                  <td v-for="value in row">{{ value }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </p>
        <div v-if="count !== null">{{ count }} {{ count > 1 ? 'candidats' : 'candidat' }}</div>
        <footer>
          <spinner v-if="csv" style="transform: scale(0.2, 0.2);"></spinner>
          <button v-else type="submit" class="sheet" title="Export Excel">Export</button>
        </footer>
      </form>
    </sweet-modal>
  </div>
</template>

<script>
import Conciliation from '/interview/interview.js'
import Candidate from '/user/candidate/candidate.entity'
import jsoncsv from 'json-csv'
import { download } from '/fun'
import encoding from 'encoding'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import Spinner from '/layout/spinner'
import { SweetModal } from 'sweet-modal-vue'
import { Labels } from '/interview/status'
import Papa from 'papaparse'
import axios from 'axios'
const CancelToken = axios.CancelToken
import { handleCancel } from '/fun'

export default {
  name: 'hippolyte.conciliation.csv',
  components: {
    Spinner, SweetModal
  },
  props: {
    total: Number,
    searches: Array || Search,
    recruiter: Object,
    start: Date,
    end: Date,
    states: Array
  },
  data () {
    return {
      Labels,
      ready: false,
      csv: null,
      current: true,
      checkedStates: Array.from(this.states),
      preview: [],
      count: null,
      previewing: null,
      cancel: null
    }
  },
  watch: {
    checkedStates () {
      this.loadPreview()
    },
    current () {
      this.loadPreview()
    }
  },
  methods: {
    onOpen () {
      this.checkedStates.splice(0, this.checkedStates.length, ...this.states)
      this.loadPreview()
      this.$refs.modal.open()
    },
    async getPage ({ limit, offset }) {
      if (this.cancel) {
        await this.cancel.cancel('overriden')
      }
      this.cancel = CancelToken.source()
      const raw = await Conciliation.list({
        solr: true,
        current: this.current,
        searches: this.searches,
        includes: { search: { location: true } },
        recruiter: this.recruiter,
        start: this.start,
        end: this.end,
        states: this.checkedStates,
        raw: true,
        limit,
        offset
      }, this.$socket, this.cancel.token)
      return [
        raw.docs.map(doc => {
          return Object.assign(doc, {
            states: this.checkedStates
            .reduce((states, state) => Object.assign({
              [state]: doc.statuses.find(s => s.status === state)?.date
            }, states), {})
          })
        }),
        raw.numFound
      ]
    },
    async loadPreview () {
      try {
        this.preview.splice(0, this.preview.length)
        this.previewing = true
        let [docs, count] = await this.getPage({ limit: 10, offset: 0 })
        this.count = count
        const csv = await jsoncsv.buffered(docs, { fields: this.getFields(docs), fieldSeparator: ';' })
        const blob = new Blob([csv])
        Papa.parse(blob, {
          complete: ({ data }) => {
            this.preview.splice(0, this.preview.length, ...data)
            this.previewing = null
          }
        })
      } catch (err) {
        handleCancel(err)
      }

    },
    async onCsv () {
      // @avoid running twice
      this.csv = true
      let offset = 0
      let count = 10
      const list = []
      const limit = 50
      let docs
      while (offset < count) {
        [docs, count] = await this.getPage({ limit, offset })
        list.push(...docs)
        offset = offset + limit
      }
      const csv = await jsoncsv.buffered(list, { fields: this.getFields(list), fieldSeparator: ';' })
      const blob = new Blob([encoding.convert(csv,'ISO-8859-1')], { type: 'text/plain;charset=ISO-8859-1' })
      this.csv = null
      download(blob, `Hippolyte_rh_${Date.now()}.csv`)
      this.$refs.modal.close()
    },
    getFields (docs) {
      const fields = this.$config.profile.reduce((acc, group) => {
        return group.fields
          .filter(field => docs.some(({ candidate }) => {
            const c = candidate.custom[(field.unprefix ? '' : 'candidat') + field.name]
            return typeof c !== 'undefined' && c !== null && (typeof c === 'string' ? c.trim().length > 0 : true)
          }))
          .concat(acc)
      }, [{ name: 'onboardSource', label: 'Source', unprefix: true }])
      const primary = [{
        name: 'candidate.id',
        label: 'Id',
        quoted: true
      },
      {
        name: 'candidate.gender',
        label: 'Civilité',
        quoted: true
      },
      {
        name: 'candidate.firstname',
        label: 'Prénom',
        quoted: true
      },
      {
        name: 'candidate.lastname',
        label: 'Nom',
        quoted: true
      },
      {
        name: 'candidate.email',
        label: 'Email',
        quoted: true
      },
      {
        name: 'candidate.phone',
        label: 'Téléphone',
        quoted: true
      },
      {
        name: 'candidate.location',
        label: 'Ville',
        quoted: true
      },
      {
        name: 'search.title',
        label: 'Titre de la search',
        quoted: true
      },
      {
        name: 'search.location.city',
        label: 'Ville de la search',
        quoted: true
      },
      {
        name: 'comment',
        label: 'Commentaire du recruteur',
        quoted: true
      }]
      const states = this.checkedStates.map(status => {
        return {
          name: ['states', status].join('.'),
          label: Labels[status].singular,
          quoted: true,
          filter: function (date) {
            try {
              return date ? format(date, 'dd/MM/yyyy') : ''
            } catch {
              return ''
            }
          }

        }
      })
      const custom = fields.map(f => {
        const me = { name: 'candidate.custom.' + (f.unprefix ? '' : 'candidat') + f.name, label: f.label, quoted: true }
        if (f.type === 'date') {
          me.filter = function (date) {
            try {
              return format(parseISO(date), 'dd/MM/yyyy')
            } catch {
              return ''
            }
          }
        }
        return me
      })
      return primary.concat(states, custom)
    }
  },
  computed: {
    thead () {
      return this.preview[0]
    },
    tbody() {
      return Array.from(this.preview).slice(1)
    }
  }
}
</script>
<style scoped lang="stylus">
@require '~/colors.styl'
.csv-export
  .sweet-content-content
    ul
      padding 0
      margin 0.4em
      display flex
      flex-direction row
      flex-wrap wrap
      list-style none
      li
        border-radius 1em
        background-color $color-iceberg
        margin 0.2em 0.6em
        padding 0.4em
        justify-self flex-start
        &:nth-last-child(-n+2)
          color white
          justify-self flex-end
    .preview
      height 20em
      position relative
      overflow-x scroll
      height 20em
      text-align left
      .floatingBarsG-container
        position absolute
        left 0
        top 0
      table
        tbody
          tr
            td
              text-overflow ellipsis
              max-width 10em
              overflow-x hidden
              white-space nowrap
</style>
