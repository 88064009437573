<template >
  <td v-if="item.transcription" class="profile-item_value" tabindex=0>
    <div class="question">{{ item.question }}</div>
    <div class="answer"  v-if="!editing && !saving">
      <div @click="transForm">{{ valueHolder }}</div>
      <div class="action">
        <!-- icone pour l'édition -->
        <div class="profile-item_edit">
          <span @click.prevent="transForm()">
            <svg viewBox="-15 -15 484.00019 484" xmlns="http://www.w3.org/2000/svg">
              <path d="m401.648438 18.234375c-24.394532-24.351563-63.898438-24.351563-88.292969 0l-22.101563 22.222656-235.269531 235.144531-.5.503907c-.121094.121093-.121094.25-.25.25-.25.375-.625.746093-.871094 1.121093 0 .125-.128906.125-.128906.25-.25.375-.371094.625-.625 1-.121094.125-.121094.246094-.246094.375-.125.375-.25.625-.378906 1 0 .121094-.121094.121094-.121094.25l-52.199219 156.96875c-1.53125 4.46875-.367187 9.417969 2.996094 12.734376 2.363282 2.332031 5.550782 3.636718 8.867188 3.625 1.355468-.023438 2.699218-.234376 3.996094-.625l156.847656-52.324219c.121094 0 .121094 0 .25-.121094.394531-.117187.773437-.285156 1.121094-.503906.097656-.011719.183593-.054688.253906-.121094.371094-.25.871094-.503906 1.246094-.753906.371093-.246094.75-.621094 1.125-.871094.125-.128906.246093-.128906.246093-.25.128907-.125.378907-.246094.503907-.5l257.371093-257.371094c24.351563-24.394531 24.351563-63.898437 0-88.289062zm-232.273438 353.148437-86.914062-86.910156 217.535156-217.535156 86.914062 86.910156zm-99.15625-63.808593 75.929688 75.925781-114.015626 37.960938zm347.664062-184.820313-13.238281 13.363282-86.917969-86.917969 13.367188-13.359375c14.621094-14.609375 38.320312-14.609375 52.945312 0l33.964844 33.964844c14.511719 14.6875 14.457032 38.332031-.121094 52.949218zm0 0"/>
            </svg>
          </span>
        </div>
        <!-- debut audio -->
        <div v-if="item.recordingUrl">
          <audio
            ref="player"
            :src="item.recordingUrl"
            @play="togglePlaying"
            @pause="togglePlaying"
            ></audio>
          <div class="player-command" @click.prevent="play" :class="playing && 'playing'">
            <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="24" height="24"><circle cx="128" cy="128" r="96" fill="none" stroke-miterlimit="10" stroke-width="16"></circle><polygon points="160 128 112 96 112 160 160 128" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polygon></svg>
          </div>
        </div>
        <!-- fin audio -->
      </div>
    </div>

    <div class="profile-item_edit_value" v-else>
      <textarea v-model="valueHolder" @change="update" :class="saving ? 'profile-item_saving' : ''" :disabled="saving" ref="inputvalue" @blur="editing=false" rows="5" cols="59" />
    </div>
  </td>
</template>
<script>

export default {
  name: 'hippolyte.profile.editable_ai_item',
  components: {},
  props: ['index', 'item'],
  data () {
    return {
      editing: false,
      valueHolder: this.item.transcription,
      saving: false,
      playing: false
    }
  },
  mounted () {},
  computed: {},
  methods: {
    togglePlaying () {
      this.playing = !this.playing
    },
    play () {
      if(this.playing) {
        this.$refs.player.pause()
      } else {
        this.$refs.player.play()
      }
    },
    transForm () {
      this.editing = true
      this.$nextTick(() => this.$refs.inputvalue.focus())
    },
    done () {
      this.saving = false
    },
    update () {
      const newQuestion = Object.assign({}, this.item, {
        transcription: this.valueHolder
      })
      this.$emit('update', {
        value: Object.assign({}, this.item, { transcription: this.valueHolder }),
        index: this.index,
        callback: this.done.bind(this)
      })
      this.saving = true
      this.editing = false
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
$r = 10px
.profile-item_value
    border inherit
    background-color $color-white
    color #404042
    overflow hidden
    overflow-wrap break-word
    a
        text-decoration none
        display inline-block
        vertical-align middle
        height 100%
    audio
        display block
        height 2em
        width 250px
    .question
      font-weight bold
      border 1 $color-astronaut_lighter solid
      border-radius $r $r 0 0
      padding 10px
      background-color $color-astronaut_lighter
      color $color-profile_second_invert
    .answer
      display flex
      justify-content space-between
      border 1px $color-astronaut_lighter solid
      border-radius 0 0 $r $r
      padding 10px
      .action
        display flex
        align-items center
        div
          padding-right 0.1em
          .player-command
            cursor pointer
            svg
              stroke black
          .playing > svg
            stroke blue
.profile-item_edit
  cursor pointer
  width 1.4em
  svg
    width 16px
    fill #45ADA7
    &:hover
      transform scale(1.1)
.profile-item_edit_value
  input
    width 200px
    &.profile-item_saving
      background $color-yellow
</style>
