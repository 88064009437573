<template>
  <ats
     v-bind="$props"
    service="talentlink"
    :savingConf="savingConf"
    :credential="{ api_key: '', slice_name: '', username: '', password: '' }"
    :cached="true"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="api_key">api_key</label>
        <input v-model="credential.api_key" id="api_key" name="api_key"/>
        <modified :enable="value.credential.api_key !== credential.api_key"/>
      </div>
      <div>
        <label for="slice_name">slice_name</label>
        <input v-model="credential.slice_name" id="slice_name" name="slice_name"/>
        <modified :enable="value.credential.slice_name !== credential.slice_name"/>
      </div>
      <div>
        <label for="username">username</label>
        <input v-model="credential.username" id="username" name="username"/>
        <modified :enable="value.credential.username !== credential.username"/>
      </div>
      <div>
        <label for="user_domain">User domain (default: BO)</label>
        <input v-model="credential.user_domain" id="user_domain" name="user_domain"/>
        <modified :enable="value.credential.user_domain !== credential.user_domain"/>
      </div>
      <div>
        <label for="password">password</label>
        <input v-model="credential.password" id="password" name="password"/>
        <modified :enable="value.credential.password !== credential.password"/>
      </div>
      <div>
        <label for="consent_id">Consent Id</label>
        <input v-model="credential.consentId" id="consent_id" name="consentId"/>
        <modified :enable="value.credential.consentId !== credential.consentId"/>
      </div>
      <div>
        <label for="conditions">Conditions supplémentaires (JSON)</label>
        <input v-model="credential.conditions" id="conditions" name="conditions"/>
        <modified :enable="value.credential.conditions !== credential.conditions"/>
      </div>
    </template>
    <template v-slot:title="{ job }">{{ title(job) }}</template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.talentlink.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    title (job) {
      return  [
        job.opening.jobNumber,
        job.jobAdTitle || job.opening.jobTitle,
        job.opening.contractType,
        job.location?.city || job.defaultJobLocation?.city || job.jobLocations?.[0]?.city
      ].filter(t => t).join(' - ')
    },
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
