<template>
  <div class="ats-container">
    <div class="ats-container_main">
      <section>
        <form @submit.stop.prevent>
          <fieldset class='recruiterConf'>
            <div>
              <label for="active" title="envoyer des candidats à l'ats quand ils passent en sent">envoyer</label>
              <input type="checkbox" v-model="config.active" @change="onUpdate" id="active" name="active"/>
              <modified :enable="config.active !== value.active"/>
            </div>
            <div title="Si il n y a pas de cv, utiliser le portrait du candidat en pdf.">
              <label for="portrait">CV en portrait</label>
              <input type="checkbox" v-model="config.autoportrait" @change="onUpdate" id="portrait" name="portrait"/>
              <modified :enable="config.autoportrait !== value.autoportrait"/>
            </div>
            <div title="Toujours utiliser le portrait du candidat en pdf pour l'envoie à l'ats même si il y a un cv.">
              <label for="forceportrait">forcer en portrait</label>
              <input type="checkbox" v-model="config.forceportrait" @change="onUpdate" id="forceportrait" name="forceportrait"/>
              <modified :enable="config.forceportrait !== value.forceportrait"/>
            </div>
            <div title="Envoyer un pdf composé du portrait et du CV">
              <label for="concatportrait">aggréger portrait et cv</label>
              <input type="checkbox" v-model="config.concatportrait" @change="onUpdate" id="concatportrait" name="concatportrait"/>
              <modified :enable="config.concatportrait !== value.concatportrait"/>
            </div>
            <div>
              <label for="autosync" title="créer des search pour les offres passant les filtres, les searchs ainsi créées seront désactiver en fonction de l'etat de publication de l'offre">search automatique</label>
              <input type="checkbox" v-model="config.autosync" @change="onUpdate" id="autosync" name="autosync"/>
              <modified :enable="config.autosync !== value.autosync"/>
            </div>
            <div v-if='isAtLeast(value, "sdk", 3, 1)'>
              <label for="ai">extraire la ville et le type de contrat du titre</label>
              <input type="checkbox" v-model="config.ai" @change="onUpdate" id="ai" name="ai"/>
              <modified :enable="config.ai !== value.ai"/>
            </div>
            <div v-if='isAtLeast(value, "sdk", 3, 2, 3)'>
              <label for="splitedLoad">charger individuellement les offres</label>
              <input type="checkbox" v-model="config.splitedLoad" @change="onUpdate" id="splitedLoad" name="splitedLoad"/>
              <modified :enable="config.splitedLoad !== value.splitedLoad"/>
            </div>
            <div v-if='isAtLeast(value, "sdk", 3, 4, 8)'>
              <label for="processTags">Process</label>
              <input v-model="config.processTags" @change="onUpdate" id="processTags" name="processTags"/>
              <modified :enable="config.processTags !== value.processTags"/>
            </div>
            <slot name="credential" :credential="config.credential" :meta="config.meta" @change="onUpdate"></slot>
            <div>
              <label for="formId">formId</label>
              <input v-model="config.formId" @change="onUpdate" id="formId" name="formId"/>
              <modified :enable="config.formId !== value.formId"/>
            </div>
            <div>
              <label for="campaign">campagne</label>
              <input v-model="config.campaign" @change="onUpdate" id="campaign" name="campaign"/>
              <modified :enable="config.campaign !== value.campaign"/>
            </div>
            <div v-if="isAtLeast(value, 'sdk', 3, 5, 8)">
              <label for="withoutAI" title="désactiver l'analyse des candidatures par l'IA">Désactiver l'IA</label>
              <input type="checkbox" v-model="config.withoutAI" @change="onUpdate" id="withoutAI" name="withoutAI"/>
              <modified :enable="config.withoutAI !== value.withoutAI"/>
            </div>
            <div v-if="isAtLeast(value, 'sdk', 3, 5, 8)">
              <label for="requireContact" title="Activer la joignabilité">Joignabilité</label>
              <input type="checkbox" v-model="config.requireContact" @change="onUpdate" id="requireContact" name="requireContact"/>
              <modified :enable="config.requireContact !== value.requireContact"/>
            </div>
            <div class="formAction">
              <input :disabled="savingConf" class="btn" value="Sauvegarder" @click="onSave">
              <button @click.stop.prevent="filters()" :disabled="filterRunning" style="position: relative;">🔍️<spinner v-if="filterRunning" style="transform: scale(0.2, 0.2); position: absolute; top: -1em;"></spinner></button>
              <span class="btn" @click="openSync" style="position: relative;" title='Synchronisation des searches avec les offres selon les filtres'>Synchro</span>
              <span class="btn" v-if="isAtLeast(value, 'sdk', 3, 4, 9)" @click="openUpdate()" style="position: relative;" title='Mise à jours des searches déjà créé qui repondent aux filtres'>Update</span>
              <template v-if="isAtLeast(value, 'sdk', 3, 4, 8)">
                <span class="btn" @click="$refs.referentiel.open()">Référentiel</span>
              </template>
            </div>
          </fieldset>
          <sweet-modal ref="referentiel" class="referentiel">
            <h4>Referentiel</h4>
            <referential
              :service="service"
              :recruiter="recruiter">
            </referential>
          </sweet-modal>
          <fieldset class="filters">
            <legend>Filtres</legend>
            <ul>
              <li v-for="(filter, i) in config.filters" :key="filter.id">
                <offer-filter
                  :filter="filter"
                  :next="i < config.filters.length - 1"
                  :previous="i > 0"
                  :versions="value.versions"
                  :filterRunning="filterRunning"
                  @up="upFilter(i)"
                  @down="downFilter(i)"
                  @remove="delFilter(i)"
                  @preview="f => filters([f])"
                  ref="filters"
                ></offer-filter>
              </li>
            </ul>
            <sweet-modal ref="filter">
              <h4>Matching jobs ({{ filtersData.length }})</h4>
              <section class="jobs">
                <ul>
                  <li v-for="job in filtersData" :key="job.id" class="job">
                    <span :class="{ synced: job.populated }">{{ title(job) }}</span>
                    <span v-if="job.hippolyte && job.hippolyte.search">{{ job.hippolyte.search.active ? '🟢' : '🔴' }}</span>
                    <a class="button" v-if="job.hippolyte && job.hippolyte.location" target="_blank" :href="osmLink(job)">🚩</a>
                    <button @click="scanJob(job)" title="preview">🍱</button>
                    <a v-if="job.offerUrl" class="button" :href="job.offerUrl" target="_blank" title="ouvrir">👁️</a>
                  </li>
                </ul>
              </section>
            </sweet-modal>

            <span class="btn" @click="addFilter">+</span>
          </fieldset>
        </form>
      </section>

      <section >
        <h4>Tranférer un candidat</h4>
        <button @click.prevent="onTransfer">Transférer</button>
        <sweet-modal ref="transfer">
          <h4>Tranférer une candidature</h4>
          <form @submit.prevent="transfer">
            <label for="conciliation">Conciliation id</label>
            <input name="conciliation" v-model="conciliation"/>
            <div v-if="cancels.transfer">
              <spinner style="transform: scale(0.2, 0.2);"></spinner>
            </div>
            <button type="submit" v-else>Transférer</button>
          </form>
        </sweet-modal>
      </section>

      <section class="jobs" style="position: relative;">
        <div>
          <h2>Offres</h2>
          <div class="checkbox-container" title="Job linké uniquement">
            <input type="checkbox" v-model="linked" :id="'linked'" />
            <label :for="'linked'"></label>
          </div>
          <span v-if="cached" class="cached-container">
            <spinner v-if="refreshingCache" style="transform: scale(0.2, 0.2)"></spinner>
            <template v-else>
              <button @click="refreshCache">🔁️</button>
              <span v-if="lastRefresh">Dernier rafraichissement: {{ lastRefresh.count }} offres le {{ lastRefresh.end }}</span>
            </template>
          </span>
        </div>
        <ul>
          <spinner v-if="busy" style="transform: scale(0.2, 0.2); position: absolute;"></spinner>
          <li v-for="job in jobs" :key="job.id" class="job">
            <span>{{ title(job) }}</span>
            <span class="action">
              <template  v-if="job.hippolyte && job.hippolyte.search">
                <span v-if="job.unpublished" class="unpublished"> Dépublié </span>
                <button v-if="isAtLeast(value, 'sdk', 3, 4, 9)" @click="openUpdate(job.hippolyte.search)" title="mettre à jour la search">màj</button>
                <button @click="openBoard(job)" class="edit-search-btn">
                  <edit-icon></edit-icon>
                </button>
                {{ job.hippolyte.search.title }}
                <span :title="`recherche ${job.hippolyte.search.active ? 'activée' : 'désactivée'}`">{{ job.hippolyte.search.active ? '🟢' : '🔴' }}</span>
                <button @click="unlink(job)" title="retirer">🗑️</button>
                <button v-if="search && search.id !== job.hippolyte.search.id" @click="link(job)" title="remplacer">🔗</button>
                <button @click="openMap(job)">🚩</button>
              </template>
              <template v-else-if="search">
                <button @click="link(job)" title="lier">🔗</button>
              </template>
              <button @click="scanJob(job)" title="preview">🍱</button>
              <a v-if="job.offerUrl" class="button" :href="job.offerUrl" target="_blank" title="ouvrir">👁️</a>
            </span>
          </li>
          <li class="fake" v-for="i in new Array(10 - jobs.length)">&nbsp;</li>
        </ul>
        <slot name="paginate">
          <paginate v-model="offset" :count="count" :limit="limit"></paginate>
        </slot>
        <sweet-modal ref="map">
          <template v-if="jobEdit">
            <h4><slot name="title" :job="jobEdit"></slot><span>{{ jobEdit.hippolyte.search.active ? '🟢' : '🔴' }}</span></h4>
            <section v-if="jobEdit.hippolyte.location.lat === null || jobEdit.hippolyte.location.lng === null">Pas de coordonéees</section>
            <section v-else>
              <iframe width="425" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" :src="osmIframeLink(jobEdit)" style="border: 1px solid black"></iframe>
              <small>
                <a :href="osmLink(jobEdit)">Afficher une carte plus grande</a>
              </small>
            </section>
            <section>
              <form @submit.stop.prevent="link(jobEdit)">
                <label for="lat">latitude</label>
                <input name="lat" v-model="jobEdit.hippolyte.location.lat"/>
                <label for="lng">longitude</label>
                <input name="lng" v-model="jobEdit.hippolyte.location.lng"/>
                <button type="submit">Changer</button>
              </form>
            </section>
          </template>
        </sweet-modal>
        <sweet-modal ref="scanJob">
          <jobDetail :job="currentScanJob" :recruiter="recruiter.id" :service="service"></jobDetail>
        </sweet-modal>
      </section>
    </div>
  </div>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Spinner from '/layout/spinner'
import cancel from '/cancel.mixin.js'
import Paginate from '/layout/paginate'
import { SweetModal } from 'sweet-modal-vue'
import { Treeselect } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { sanitize } from 'dompurify'
import { VueEditor } from 'vue2-editor/dist/vue2-editor.umd.min'
import { isAtLeast } from '../fun'
import EditIcon from '/icons/edit'
import OfferFilter from '/ats/filter'
import Modified from '/ats/modified'
import Referential from './referential'
import JobDetail from './job-detail'

export default {
  name: 'hippolyte.services.ats.config',
  components: {
    VueEditor, Treeselect, Paginate, Spinner, SweetModal, EditIcon, OfferFilter, Modified, Referential, JobDetail
  },
  mixins: [cancel],
  provide () {
    return {
      'recruiter': this.recruiter
    }
  },
  props: {
    value: Object,
    credential: Object,
    service: String,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean,
    cached: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    offset: 'load',
    linked: function () {
      this.offset = 0
      this.load()
    },
    savingConf: function (next) {
      if (!next) {
        this.load()
      }
    },
    jobs: function() {
      this.$emit('update')
    },
    value: {
      handler () {
        this.config = this.capture()
      },
      deep: true
    },
    recruiter: {
      handler (value, old) {
        if (value.id !== old.id) {
          if (this.cached) {
            this.removeListenCached(old)
            this.listenCached(value)
          }
          this.load()
        }
      },
      deep: true
    },
    cached: {
      handler (value) {
        if (value) {
          this.listenCached(this.recruiter)
        } else {
          this.removeListenCached(this.recruiter)
        }
      }
    }
  },
  data () {
    return {
      config: this.capture(),
      jobs: [],
      limit: 10,
      offset: 0,
      count: 0,
      busy: false,
      selected: null,
      conciliation: null,
      synchroRunning: false,
      filterRunning: false,
      confirm: null,
      filtersData: [],
      jobEdit: null,
      linked: false,
      refreshingCache: false,
      lastRefresh: this.value.lastRefresh || null,
      currentScanJob: null
    }
  },
  mounted () {
    this.load()
    this.$socket.sub(`/ats/${this.service}/recruiter/${this.recruiter.id}`, 'SCAN_DONE', ({ body }) => {
      this.$toast(`désactivé: ${body.data.unactivated.length}, activé: ${body.data.activated.length}, créé: ${body.data.created.length}`, { theme: 'success' })
      this.load()
      this.synchroRunning = false
    })
    if (this.cached) {
      this.listenCached(this.recruiter)
    }
  },
  destroyed () {
    this.$socket.unsub(`/ats/${this.service}/recruiter/${this.recruiter.id}`, 'SCAN_DONE')
    if (this.cached) {
      this.removeListenCached(this.recruiter)
    }
  },
  methods: {
    capture () {
      const k = Object.assign({
        active: false,
        credential: JSON.parse(JSON.stringify(this.credential)),
        formId: null,
        campaign: null,
        autosync: false,
        ai: false,
        splitedLoad: false,
        autoportrait: false,
        concatportrait: false,
        forceportrait: false,
        processTags: '',
        filters: [],
        meta: ''
      }, JSON.parse(JSON.stringify(this.value)))
      return k
    },
    openBoard (job) {
      try {
        window.open([
          document.location.protocol, '',
          document.location.host.replace('conciliator', 'conciliateur'),
          'recruiter',
          this.recruiter.id,
          'search',
          job.hippolyte.search.id,
          'edit'
        ].join('/'))
      } catch (err) {
        console.log(err)
      }
    },
    addFilter () {
      this.config.filters.push({
        id: Math.random().toString(36).substr(2, 5)
      })
    },
    delFilter (at) {
      this.config.filters.splice(at, 1)
    },
    upFilter (at) {
      const [item] = this.config.filters.splice(at, 1)
      this.config.filters.splice(at - 1, 0, item)
    },
    downFilter (at) {
      const [item] = this.config.filters.splice(at, 1)
      this.config.filters.splice(at + 1, 0, item)
    },
    select (job) {
      this.selected = this.selected === job ? null : job
    },
    openUpdate (search) {
      this.$confirm().open({ title: search ? 'Etes-vous sûr de vouloir mettre à jours la search ?' : 'Etes-vous sûr de vouloir mettre à jours toutes les searches ?', valid:'Mettre à jour' })
        .then(() => {
          this.$socket.service(`${this.service}.jobs/UPDATE`, {
              recruiter: this.recruiter.id,
              search: search ? search.id || search : null
            }).then(() => {
              this.$toast('mise à jour OK', { theme: 'success' })
            }, () => {
              this.$toast('la mise à jour a échoué', { theme: 'error' })
            })
            .finally(() => {
              this.$confirm().close()
            })
        }, () => {})
    },
    openSync () {
      this.$confirm().open({ title:'Etes-vous sûr de vouloir synchroniser manuellement ?', valid:'Synchroniser' })
        .then(() => {
          this.synchro()
        }, () => {})
    },
    async synchro () {
      const data = await this.$socket.service(`${this.service}.jobs/SYNC`, {
        recruiter: this.recruiter.id
      })
        .then(() => {}, () => {
          this.$toast('la syncro a échoué', { theme: 'error' })
        })
        .finally(() => {
          this.$confirm().close()
        })
      await this.load()
    },
    async filters (filters) {
      this.filterRunning = true
      if (!filters) {
        filters = this.$refs.filters?.map(f => f.capture())
      }
      try {
        const data = await this.$socket.service(`${this.service}.jobs/FILTER`, {
          recruiter: this.recruiter.id,
          filters
        })
        this.filtersData.splice(0, this.filtersData.length, ...data)
        this.$refs.filter.open()
      } catch {
        this.$toast('le filtre a échoué', { theme: 'error' })
      }
      this.filterRunning = false
    },
    onUpdate () {
      for(const key in this.config.credential) {
        if(typeof this.config.credential[key] === 'string') {
          this.config.credential[key] = this.config.credential[key].trim()
        }
      }
    },
    onSave () {
      this.config.filters.splice(0, this.config.filters.length, ...(this.$refs.filters?.map(f => f.capture()) || []))
      this.onUpdate()
      this.$emit('input', this.config)
    },
    async load () {
      this.cancel('jobs')
      this.busy = true
      this.selected = null
      try {
        const data = await this.$socket.service(`${this.service}.jobs/LIST`, {
          linked: this.linked,
          recruiter: this.recruiter.id,
          limit: this.limit,
          offset: this.offset
        }, { cancel: this.token('jobs') })
        this.jobs.splice(0, this.limit, ...data.docs)
        this.count = data.count
        this.cancel('jobs', null)
        this.busy = false
      } catch (err) {
        try {
          this.handleCancel(err)
        } catch {
          this.busy = false
        }
      }
    },
    async link (job) {
      try {
        const data = await this.$socket.service(`${this.service}.jobs/LINK`, {
          search: job.hippolyte?.search.id || this.search.id,
          job: job.id,
          location: job.hippolyte?.location
        })
        Object.assign(job, data)
        this.$toast(`${job.title} lié à ${job.hippolyte.search.title}`, { theme: 'success' })
        this.$forceUpdate()
      } catch (err) {
        if (err.response && err.response.status === 409) {
          return this.$toast(`Une autre recherche est déjà lié à cet offre`, { theme: 'error' })
        }
        this.$toast(`${job.title} n'a pas pu être lié à ${this.search.title || this.search.id}`, { theme: 'error' })
      }
    },
    async unlink (job) {
      try {
        const title = job.hippolyte.search.title
        await this.$socket.service(`${this.service}.jobs/LINK`, {
          job: job.id || job.reference,
          recruiter: this.recruiter.id
        })
        Object.assign(job, { hippolyte: { search: null } })
        this.$toast(`${title} n'as plus d'offre`, { theme: 'success' })
        const f = this.jobs.find(j => j.id === job.id)
        if (f) {
          f.hippolyte = undefined
          this.$forceUpdate()
        }
      } catch {
        this.$toast(`${title} n'a pas pu être délié`, { theme: 'error' })
      }
    },
    onTransfer () {
      this.$refs.transfer.open()
    },
    async transfer () {
      this.cancel('transfer')
      try {
        const data = await this.$socket.service(`${this.service}.jobs/SEND`, {
          conciliation: this.conciliation
        }, { cancel: this.token('transfer') })
        this.$toast(`${this.candidate} transféré`, { theme: 'success' })
        this.cancel('transfer', null)
        this.$refs.transfer.close()
      } catch (err) {
        this.$toast(`${this.conciliation} n'a pas pu être transféré`, { theme: 'error' })
        this.cancel('transfer', null)
        this.handleCancel(err).catch()
      }
    },
    osmLink (job) {
      const params = `mlat=${job.hippolyte.location.lat}&mlon=${job.hippolyte.location.lng}`
      const hash = [
        'map=7',
        job.hippolyte.location.lat,
        job.hippolyte.location.lng
      ].join('/')
      return `https://openstreetmap.org/?${params}#${hash}`
    },
    osmIframeLink (job) {
      const { lat, lng } = job.hippolyte.location
      const bbox = [
        lng - 2,
        lat - 2,
        lng + 2,
        lat + 2
      ].join('%2C')
      const params = `bbox=${bbox}&marker=${lat}%2C${lng}`
      return `https://openstreetmap.org/export/embed.html?${params}`
    },
    openMap (job) {
      this.jobEdit = job
      if (!job.hippolyte.location) {
        job.hippolyte.location = { lat: null, lng: null }
      }
      this.$refs.map.open()
    },
    isAtLeast (lib, ...args) {
      return isAtLeast(this.value.versions, ...args)
    },
    title (job) {
      return  [
        job.reference,
        job.unpublished ? job.hippolyte?.search.title : job.title,
        job.unpublished ? 'plus de contrat' : job.contracts,
        job.unpublished ? job.hippolyte?.search.location?.docs[0]?.city : job.city
      ].filter(t => t).join(' - ')
    },
    async scanJob (job) {
      this.currentScanJob = job.id
      this.$refs.scanJob.open()
    },
    listenCached (recruiter) {
      this.$socket.sub(`/ats/${this.service}/recruiter/${recruiter.id}`, 'CACHE_START', this.onCacheStart.bind(this))
      this.$socket.sub(`/ats/${this.service}/recruiter/${this.recruiter.id}`, 'CACHE_DONE', this.onCacheDone.bind(this))
      this.$socket.sub(`/ats/${this.service}/recruiter/${this.recruiter.id}`, 'CACHE_ERROR', this.onCacheError.bind(this))
    },
    removeListenCached (recruiter) {
      this.$socket.unsub(`/ats/${this.service}/recruiter/${recruiter.id}`, 'CACHE_START')
      this.$socket.unsub(`/ats/${this.service}/recruiter/${recruiter.id}`, 'CACHE_DONE')
      this.$socket.unsub(`/ats/${this.service}/recruiter/${recruiter.id}`, 'CACHE_ERROR')
    },
    onCacheStart () {
      this.refreshingCache = true
    },
    onCacheDone (diff) {
      const { body: { data } } = diff
      this.lastRefresh = data
      this.refreshingCache = false
      const msg = ['added', 'removed'].map(k => {
        const count = data[k].length
        const plural = count > 1 ? 's' : ''
        const action = { added: 'ajouté', removed: 'supprimé' }[k]
        return `${count} offre${plural} ${action}${plural}`
      }).join(', ') + ` pour un total de ${data.count} en ${data.duration}ms`
      this.$toast(msg, { theme: 'success' })
      this.load()
    },
    onCacheError () {
      this.refreshingCache = false
      this.$toast('Le rafraichissement du cache a échoué', { theme: 'error' })
    },
    refreshCache () {
      this.$socket.service(`${this.service}.jobs/REFRESH`, {
        recruiter: this.recruiter.id
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~/colors.styl'
input[type="checkbox"]
  height auto
form
  margin 1em
  display flex
  flex-wrap wrap
  fieldset
    width calc(45% - 2em)
    border 1px #eee solid
    &.recruiterConf
      position sticky
      top 2em
      height fit-content
    &.filters
      ul
        padding-left 1em
        li
          background white
          margin 1em 0
          padding 1em 0.5em
          list-style-type none
          section:first-child
            justify-content start
          details
            section div
              display flex
              div
                display inline
                label
                  text-align left
                  display block
            &>.description
              justify-content center
              .quillWrapper
                min-width 600px
                .ql-container
                  font-family 'Montserrat'
            &>.tradeSelect
              justify-content center
            section, .action
              display flex
              justify-content end
              .btn
                display flex
                align-items center
                background-color $color-torrent
  .formAction
    display flex
    background-color transparent
  textarea
    background #eee
    border-radius 0 4px 4px 0
    font-size 1em
    line-height 1.5em
    border 1px #eee solid
    width 80%
    height 100px
    &:focus
      border 1px #ccc solid
  div
    display grid
    grid-template-columns 150px 1fr
    background white
    margin 0.4em 0
    padding 3px 3px 3px 0.8em
    border-radius 4px
    align-items center
    label
      grid-column 1
      margin-right 1em
      text-align right
      margin-top auto
      margin-bottom auto
    input, select
      grid-column 2 / 3
      background #eee
      border-radius 0 4px 4px 0
      font-size 1em
      line-height 1.5em
      border 1px #eee solid
      &:focus
        border 1px #ccc solid
    button
      grid-column 3
    small
      color red
      grid-column 2
    .modified
      grid-column 3
button, .btn
  background white
  border none
  color black
  border-radius 2em
  line-height 1em
  margin 0.2em 0
  padding 0.4em 1.5em
  font-size 0.9em
  cursor pointer
  text-decoration none
  &::first-letter
    text-transform capitalize
  &:focus, &:hover
    outline none
  &.invert
    color white
    border 1px white solid
  &:disabled
    color #ccc
    cursor default
  #floatingBarsG-container
    width auto
.ats-container
  width 100%
  .ats-container_main
    width 100%
    .checkbox-container
        display flex
        padding-left 1em
        switch($color-green, white)
        align-items center
    section.jobs
      div
        display flex
        align-content end
      .cached-container
        display flex
        align-items center
        font size 0.8em
        span
          margin-left 1em
          font-size 0.8em
      ul
        padding 1em
        list-style none
        .synced
          text-decoration-line line-through
        li.job
          background white
          margin 0.5em 0
          width auto
          padding 0.4em 0.8em
          border-radius 1em
          cursor pointer
          display flex
          justify-content space-between
          .action
            button, .button
              background $color-torrent
              padding 0.2em 0.4em
              margin 0.1em
              border-radius 50%
            &.fake
              background #e8e8e8
            .unpublished
              color red
          .edit-search-btn svg
            height 1em
            &:hover
              fill white
      .code
        font-size 0.8em
        background-color $color-torrent
        white-space pre-wrap

</style>
