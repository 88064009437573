<template>
  <section class="candidate-container hippo_admin" v-if="profile && !hide">
    <div class="candidate-infos-container">
      <article class="candidate_presentation">
        <candidate-card :interview="interview" :candidate="profile" :key="profile.id" editable @avatar="() => updateValue({ hideAvatar: !hideAvatar})"></candidate-card>
        <spinner v-if="loadingConciliations" style="transform: scale(0.2, 0.2);"></spinner>
        <ul class="conciliation_list" v-for="conciliation in conciliations" v-else :key="conciliation.id">
          <conciliation-item :conciliation="conciliation" :reasons='reasons'></conciliation-item>
        </ul>
      </article>
      <div class="actions-wrapper">
        <archive v-if="interview" :interview="interview" @archive="$emit('archive', interview)"></archive>
        <button v-else @click.prevent="createConciliation" class="contact" >
          <div class="icon-wrapper">
            <img class="contact-icon" src="/assets/icon/recommendations_white.png"/>
          </div>
          <span>Recommander</span>
        </button>
        <button v-if="openable && profile.firstname" @click.prevent="toggleChat" class="contact">
          <presence :profile="profile"></presence>
          <div class="icon-wrapper">
            <img class="contact-icon" src="/assets/icon/started.png"/>
          </div>
          <span>Contacter</span>
        </button>
      </div>
      <article class="candidate_params" v-if="aiData && aiData.aiResult">
        <header>Analyse IA</header>
        <div>
          <tr class="profile_item">
            <td class="profile-item_name">Score</td>
            <td class="profile-item_value">{{ aiData.aiScore }}</td>
          </tr>
          <tr class="profile_item">
            <td class="profile-item_name">Version</td>
            <td class="profile-item_value">{{ aiData.aiVersion }}</td>
          </tr>
          <profile-editable-item
            v-model="aiData.aiResult.assessment"
            :field="{ label:'Analyse', name:'aiResult', base_name:'assessment', type:'AI' }"
            @update="updateAiData"
          ></profile-editable-item>
          <profile-editable-item
            v-model="aiData.aiResult.summary"
            :field="{ label:'Résumé', name:'aiResult', base_name:'summary', type:'AI' }"
            @update="updateAiData"
          ></profile-editable-item>
          <header v-if="isVoicebotValid()">Screen AI</header>
          <voice-call-editable-list
            v-model="aiData.aiResult.voicebot_interview"
            :field="{ label:'Screen AI', name:'aiResult', base_name:'voicebot_interview', type:'AI' }"
            @update="updateAiData"
          ></voice-call-editable-list>
        </div>
      </article>
      <template v-for="group in groups">
        <article class="candidate_params" v-if="groupHasSomeField(group)" :key="group.name">
          <header>
            {{ group.name }}
            <div class="field_empty-visibility">
              <input type="checkbox" v-model="showEmpty" :id="'switch_'+group.name" />
              <label :for="'switch_'+group.name" :title="showEmpty ? 'Cacher les champs vides' : 'Montrer les champs vides'"></label>
            </div>
          </header>
          <profile-editable-item v-model="profile.custom.babychouReference" :ref="fieldReference.base_name" @update="updateCustomValue" :field="fieldReference"></profile-editable-item>
          <profile-editable-item v-model="profile.custom.onboardSource" :ref="'custom_field_onboard_source'" @update="updateCustomValue" :field="{name:'onboardSource',label:'Source',unprefix:true,base_name:'custom_field_onboard_source'}"></profile-editable-item>
          <template v-for="field in group.fields">
            <profile-editable-item
              v-if="field.base_name !== 'custom_field_babychou_reference' && field.base_name !== 'custom_field_answer'"
              v-show="profile.custom[(field.unprefix ? '' : 'candidat')+ field.name] || showEmpty"
              v-model="profile.custom[(field.unprefix ? '' : 'candidat')+ field.name]"
              :field="field"
              :ref="field.base_name"
              @update="updateCustomValue"
              :key="field.base_name"/>
          </template>
        </article>
      </template>
      <article class="candidate_params" v-if="hasConciliationAnswer()">
        <header>Questionnaire</header>
        <tr v-if="aiData && aiData.aiResult" class="profile_item">
          <td class="profile-item_name">Score de test</td>
          <td class="profile-item_value">{{ aiData.aiResult.test_score }}</td>
        </tr>
        <template v-for="item in getConciliationAnswer()">
          <tr class="profile_item" v-if="item && item.answer">
            <td class="profile-item_name">{{ item.question }}</td>
            <td class="profile-item_value">{{ item.answer }}</td>
          </tr>
        </template>
      </article>
      <article class="candidate_params" v-if="hasCandidateAnswer()">
        <header>Préqualification sur le candidat</header>
        <template v-for="item in getCandidateAnswer()">
          <tr class="profile_item" v-if="item && item.answer">
            <td class="profile-item_name">{{ item.question }}</td>
            <td class="profile-item_value">{{ item.answer }}</td>
          </tr>
        </template>
      </article>
      <article class="candidate_internal-comment-container">
        <header>Commentaire</header>
        <internal-comment v-if="editInternalComment" v-model="profile" @update="toggleEditInternalComment"></internal-comment>
        <div v-else>
          <p>{{ profile.internalComment }}</p>
          <button @click="toggleEditInternalComment" class="picto_btn">
            <div class="icon-wrapper">
              <img src="/assets/icon/comment_blue.png"/>
            </div>
            <span>Modifier</span>
          </button>
        </div>
      </article>
      <spinner v-if="loadingConciliations" style="transform: scale(0.2, 0.2);"></spinner>
      <template v-for="conciliation in conciliations" v-else >
        <conciliation-comment :conciliation="conciliation" v-if="conciliation.comment" :key="conciliation.id"></conciliation-comment>
      </template>
      <article class="candidate_comment-container" v-if="profile.coordinates">
        <header>Map</header>
        <profile-map v-model="profile.coordinates" v-if="typeof profile.coordinates === 'string'"></profile-map>
      </article>
    </div>
    <footer>
      <div class="profile_edit-btn-group">
        <button @click="openBoard" class="profile_edit-link">
          <font-awesome-icon icon="user-edit" size="lg"></font-awesome-icon>
          Editer sur le board
        </button>
        <button @click="openConciliation" class="profile_edit-link">
          Voir les conciliations
        </button>
      </div>
      <button class="profile-interview-open"
        v-if="interview && !interview.isRejected()"
        @click="() => $emit('open')">
        Discuter avec le candidat
      </button>
    </footer>
    <chat-ligth
      :openable="openable"
      :show="show"
      class="chat"
      :profile="profile"
      :room="room"
      :conciliation="selectedConciliation"
      @reduce="toggleChat"
      @close="closeChat"
    ></chat-ligth>
  </section>
</template>
<script>
import ProfileItem from '/profile/item'
import ProfileEditableItem from '/profile/editable_item'
import VoiceCallEditableList from '/profile/voicecall_editable_list'
import Candidate from '/user/candidate/candidate.entity.js'
import Recruiter from '/user/recruiter/recruiter.entity.js'
import Interview from '/interview/interview.js'
import CandidateCard from '/user/candidate/candidate-item'
import Archive from '/user/candidate/tools_archive'
import Comment from '/profile/comment.vue'
import InternalComment from '/profile/internal-comment.vue'
import ProfileMap from '/profile/map'
import Conciliation from '/interview/interview.js'
import Search from '/requirement/entity'
import ConciliationItem from '/profile/conciliation-item'
import ConciliationComment from '/profile/conciliation-comment'
import Spinner from '/layout/spinner'
import debug from 'debug'
import axios from 'axios'
const CancelToken = axios.CancelToken
import { EventBus } from '../event-bus'
import { handleCancel } from '/fun'
import Presence from './presence.vue'
import ChatLigth from '/chat/chatLigth'

export default {
  name: 'hippolyte.profile',
  components: {
    ChatLigth,
    Presence,
    ProfileItem,
    ProfileEditableItem,
    VoiceCallEditableList,
    CandidateCard,
    Archive,
    Comment,
    InternalComment,
    ProfileMap,
    ConciliationItem,
    ConciliationComment,
    Spinner
  },
  props: {
    profile: Candidate,
    interview: {
      type:  Interview,
      default: null
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.room?.quit()
    this.show = false
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.room?.quit()
    this.show = false
    this.openable = this.$route.meta.chat ?? false
    next()
  },
  watch: {
    profile (val, old) {
      old?.off('update', this.updateProfile, this)
      val?.on('update', this.updateProfile, this)
      this.updateProfile()
      this.$forceUpdate()
    },
    id () {
      this.loadConciliations()
    }
  },
  mounted () {
    this.loadConciliations()
    this.loadReasons()
    EventBus.$on('hide-profile', hide => this.hide = hide)
  },
  destroy () {
    this.profile.off('update', this.updateProfile)
  },
  data () {
    if (this.profile) {
      this.profile.on('update', this.updateProfile, this)
      return Object.assign(this.profile.marshall(), {
        openable: this.$route.meta.chat ?? false,
        show: false,
        room: null,
        savings: {},
        editComment: false,
        editInternalComment: false,
        conciliations: [],
        loadingConciliations: false,
        showEmpty: false,
        cancel: null,
        hide: false,
        aiData: null,
        reasons: []
      })
    } else {
      return {
        openable: this.$route.meta.chat ?? false,
        show: false,
        room: null,
        savings: {},
        editComment: false,
        editInternalComment: false,
        conciliations: [],
        loadingConciliations: false,
        showEmpty: false,
        cancel: null,
        hide: false,
        aiData: null,
        reasons: []
      }
    }
  },
  methods: {
    async loadReasons () {
      try {
        this.cancel = CancelToken.source()
        const reasons = await Conciliation.getStatusReasons({}, this.$socket, this.cancel.token)
        if (reasons.numFound) {
          this.reasons.splice(0, this.reasons.length, ...reasons.docs)
        }
      } catch (err) {
          if (!axios.isCancel(err)) {
            throw err
          }
      }
    },
    hasCandidateAnswer () {
      return (this.custom.answerQ1 === 'AI' && this.custom.answer && !this.hasConciliationAnswer()) ?
        this.getCandidateAnswer().some(q => q.answer) : false
    },
    getCandidateAnswer () {
      let answers
      try {
        answers = JSON.parse(this.custom.answer)
      } catch {}
      return Object.values(answers || this.custom.answer)
    },
    hasConciliationAnswer () {
      return (this.aiData && this.aiData.formAnswer) ?
        this.getConciliationAnswer().some(q => q.answer) : false
    },
    getConciliationAnswer () {
      return Object.values(this.aiData?.formAnswer)
    },
    closeChat (room) {
      this.show = !this.show
      this.$chat.close(room)
    },
    toggleChat () {
      this.show = !this.show
      this.openRoom()
    },
    openRoom () {
      if(this.profile.id) {
        const room = this.$chat.rooms.find(room => {
          try {
            return room.id.split('/')[3] === this.profile.id
          } catch (err) {
            console.log(err)
          }
        })
        if(this.show && !room){
          const fn = room => {
            if (room.id.split('/').includes(this.profile.id)) {
              this.room = room
              this.$chat.off('room:update', fn)
            }
          }
          this.$chat.on('room:update', fn)
          this.$emit('start', this.interview)
        } else {
          this.room = room
          this.room?.history()
        }
      }
    },
    async loadConciliationData () {
      if (this.cancel) {
        await this.cancel.cancel('conciliationData')
      }
      let cancel = CancelToken.source()
      this.cancel = cancel
      this.aiData = null
      const { docs: data, numFound } = await Conciliation.getConciliationData({
        conciliation: this.$route.params.interview,
        limit: 10,
        offset: 0
      }, this.$socket, cancel.token).catch(handleCancel)
      if(numFound) {
        data[0].aiResult = data[0].aiResult && JSON.parse(data[0].aiResult)
        data[0].formAnswer = data[0].formAnswer && JSON.parse(data[0].formAnswer)
        this.aiData = data[0]
      } else {
        this.aiData = null
      }
      this.cancel = null
    },
    async loadConciliations () {
      if (this.profile && this.profile.id) {
        if (this.conciliations.find(c => c.candidate.id === this.id)) {
          return
        }
        if (this.cancel) {
          await this.cancel.cancel('conciliations, candidate update')
        }
        let cancel = CancelToken.source()
        this.cancel = cancel
        this.loadingConciliations = true
        this.conciliations.splice(0, this.conciliations.length)
        let opts = {
          candidates: [this.profile],
          solr: true,
          includes: {
            recruiter: true,
            search: { trade: true, location: true },
            reasons: true,
          },
          dir: 'desc'
        }
        Conciliation.list(opts, this.$socket, cancel.token)
        .then(async ({ docs: conciliations }) => {
          this.cancel = null
          this.loadingConciliations = false
          this.conciliations.splice(0, this.conciliations.length, ...conciliations)
        }).catch(handleCancel)
      }
    },
    updateAiData({field, value, callback}){
      this.aiData[field.name][field.base_name] = value
      Conciliation.saveConciliationData({id: this.aiData.id, field, value: this.aiData[field.name] }, this.$socket)
        .then(
          (...args) => {
            this.$toast(`Champ ${field.label} modifié`, { theme: 'success' })
            callback(...args)
          },
          err => this.onSaveError(err, field)
        )
    },
    updateCustomValue ({field, value, callback}) {
      this.profile.saveField(field, value)
        .then(
          (...args) => {
            this.$toast(`Champ ${field.label} modifié`, { theme: 'success' })
            callback(...args)
          },
          err => this.onSaveError(err, field)
        )
    },
    onSaveError (err, field) {
      this.$toast(`Impossible de modifier le champ ${field.label}`, { theme: 'error' })
    },
    updateValue (data) {
      this.profile.save(data)
    },
    updateProfile () {
      if (!this.profile) { return }
      Object.assign(this, this.profile.marshall())
      this.loadConciliations()
      this.loadConciliationData()
      this.hide = false
      this.editInternalComment = false
      this.$forceUpdate()
    },
    groupHasSomeField (group) {
      return group.fields.some(field => {
        return ((field.prefix || 'candidat') + field.name) in this.profile.custom
      })
    },
    openBoard () {
      try {
        window.open([
          document.location.protocol, '',
          document.location.host.replace('conciliator', 'conciliateur'),
          'candidate',
          this.profile.id,
          'edit'
        ].join('/'))
      } catch (err) {
        console.log(err)
      }
    },
    openConciliation () {
      try {
        window.open([
          document.location.protocol, '',
          document.location.host.replace('conciliator', 'conciliateur'),
          'candidate',
          this.profile.id,
          'conciliation'
        ].join('/'))
      } catch (err) {
        console.log(err)
      }
    },
    setEditComment () {
      this.editComment = !this.editComment
    },
    async createConciliation () {
      try {
        await this.interview.save()
        await this.interview.requireAcceptation()
        this.$toast(`Poste chez ${this.interview.recruiter.company} proposé à ${this.interview.candidate.firstname} ${this.interview.candidate.lastname}`, { theme: 'success'})
        this.$router.push({
          name: 'recommendations_search',
          params: {
            search: this.interview.search.id
          }
        })
        EventBus.$emit('conciliation:waiting_acceptance', this.interview)
      } catch {
        this.$toast('Erreur, la recommendation n\'a pas était créé', { theme: 'error'})
      }
    },
    toggleEditInternalComment () {
      this.editInternalComment = !this.editInternalComment
    },
    isVoicebotValid () {
      if (this.aiData?.aiResult?.voicebot_interview) {
        return this.aiData.aiResult.voicebot_interview.some(interaction => interaction.transcription)
      }
      return false
    }
  },
  computed: {
    groups () {
      return this.$config.profile || []
    },
    fieldReference () {
      return this.groups[0]?.fields.find(field => field.base_name === 'custom_field_babychou_reference')
    },
    selectedConciliation () {
      return this.conciliations.find(c => c.id === this.$route.params.interview)
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
.candidate-container {
    background-color: $color-white;
    color: $color-profile_text;
    color: black;
    display: none
    flex-direction: column;
    border-left 2px solid #45ADA7
    &.show {
        display: flex
    }
    article header {
        ticks()
        margin-bottom: 0.7em;
        font-size: 1em;
        font-weight: 700;
    }
    .chat {
      position absolute
      z-index 5
      bottom 0.5em
      right  25%
      height 60%
      width 19%
      border 2px solid $color-green
      border-radius 5%
      background-color $color-blue_grey_light
    }
    .candidate-infos-container {
        position relative
        height: 100%;
        overflow: scroll;
        > * {
            margin: 1em 1em 0 1em
        }
        .candidate_presentation {
            .card_name {
                margin-top: 0
            }
            .avatar {
                width 80px
                height 80px
            }
            svg {
                top 55px
            }
        }
        .profile-item_name {
          background-color #45ada7
        }
        .profile-item_value {
          color #404042
          .question {
            font-weight bold
          }
          .answer {
            padding-bottom 0.5em
          }
        }
        .profile_avatar {
            text-align center
        }
        .profile_name {
            font-weight bold
        }
        .profile_location {
            font-weight normal
        }
        .profile_age {
            font-weight normal
        }
        .profile_presentation {
            margin 0 0 0.2em 0
        }
        .profile_cv img {
            width 50px
        }
        .field_empty-visibility {
          margin-left 1em
          switch(#45ADA7, $color-white)
        }
    }
    .actions-wrapper {
        margin: 0;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        & > * {
            margin-bottom 3px
            width: fit-content;
        }
        button {
          &.contact {
            &:hover {
              color: $color-yellow
            }
            background $color-astronaut
            color $color-white
            border 2px $color-yellow solid
            border-radius 1em
            margin 0.2em
            padding 0.2em
            font-size 1.2em
            width 2em
            height 2em
            display block
            text-align center
            cursor pointer
            position relative
          }
          img.contact-icon {
            height 1.2em
            width 1.2em
            margin 0
            padding 0
          }
          .icon-wrapper {
            background $color-blue_lighter
            border-radius 2em
            border 1px $color-blue_lighter solid
            margin 0
            padding 0.2em
            height 1.2em
            width 1.2em
          }
          &.contact {
            width: auto
            padding 0 !important
            margin 0
            display flex
            flex-direction row
            align-items center
            border 3px #45ADA7 solid !important
            font-family: $font
            font-size: 0.8em;
            submiter()
          }
        }
    }
    .conciliation_list {
      list-style none
      margin 0
      padding 0
      font-size 0.9em
      li {
        margin 0.3em 0
        padding 0
      }
    }
    footer {
        display block
        .profile_edit-link {
          cursor pointer
        }
        .profile_edit-btn-group {
          width 100%
          display flex
          gap 0.5em
          }

        .profile-interview-open, .profile_edit-link {
            background-color #45ADA7
            color $color-profile_back
            margin: 0;
            border: 0;
            padding: 0.4em;
            font-weight: bold;
            font-size: 1em;
            flex 1
        }

        .profile_edit-link {
          padding 0.6em
        }
    }
}

.picto_edit_comment {
  width: 30px;
}

@media (min-width: $breakpoint)
    .candidate-container
        display: flex
    .profile-interview-open
        display: none;
    .candidate_presentation
        margin-top 1rem !important
    .candidate-container .profile_item > *
        padding 0.5em !important
    .candidate-container > * header
        margin-top 2em
    .candidate-infos-container
        overflow: hidden !important;
        overflow-y: auto !important;
    .candidate_comment-container icon-wrapper img
      margin-left 2px
</style>
